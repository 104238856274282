@font-face {
  font-family: 'Abel';
  src: url('./Abel-Regular.ttf') format('truetype');
}

.App {
  font-family: 'Abel';
}

.App-header {
  background-color: #545454;
  height: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: white;
}

.App-body {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.2em;
  position: relative;
  overflow: hidden;
}

.App-imageCard {
  display: flex;
  width: 360px;
  height: 640px;
  flex-shrink: 0;
  background-image: url(/public/bathroom.png);
  border-radius: 10px 10px 0px 0px;
}

.App-card {
  background-color: #cfcfcf;
  height: 280px;
  width: 360px;
  border-radius: 10px 10px 10px 20px;
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  z-index: 1; /* Add this line */
  margin-top: -10px; /* Add this line */
}

.App-title {
  color: rgb(0, 0, 0);
  font-size: 2.5rem;
  font-family: 'Abel';
  align-self: left;
  margin-left: 20px;
}

.App-para {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  font-family: 'Abel';
  align-self: left;
  margin-left: 20px;
}

.App-button {
  color: rgb(255, 255, 255);
  border-radius: 10px;
  background: #296154;
  width: 200px;
  height: 2.2rem;
  font-size: 1.5rem;
  font-weight: bold;
  align-self: center;
  margin-top: 20px;
  font-family: 'Abel';
}

.App-backButton {
  border-radius: 10px;
  width: 40px;
  height: 2.2rem;
}

.App-inputContainer {
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.App-basicCardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.App-basicCard {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 360px;
  position: absolute;
  z-index: 100;
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  opacity: 0;
  animation: slideIn 1s forwards;
  animation-delay: 0.2s;
}

.App-basicCardTitle {
  font-size: 1.8rem;
  margin-bottom: 5px;
}

.App-basicCardInput {
  padding: 10px;
  font-size: 1rem;
  font-family: 'Abel';
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #cfcfcf;
  margin-bottom: 10px;
}

.App-basicCardSelect {
  padding: 10px;
  font-size: 1rem;
  font-family: 'Abel';
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #cfcfcf;
  margin-bottom: 10px;
}

.App-surveyCardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}
.App-surveyCard {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 360px;
  position: absolute;
  z-index: 102;
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  opacity: 0;
  animation: slideIn 1s forwards;
  animation-delay: 0.2s;
}

.App-surveyCardTitle {
  font-size: 1.8rem;
  margin-bottom: 5px;
}
.App-surveyCardItem {
  background-color: #cfcfcf;
  border-radius: 10px;
  width: 360px;
  height: 69px;
  margin-bottom: 10px;
  position: relative;
}

.App-surveyCardItemTitle {
  font-size: 15px;
  color: #848484;
  margin-left: 10px;
}

.App-surveyCardItemValue {
  font-size: 1rem;
  color: #848484;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
}

.App-scanCardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 103;
}
.App-scanCard {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  width: 360px;
  position: absolute;
  z-index: 104;
  display: inline-flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  opacity: 0;
  animation: slideIn 1s forwards;
  animation-delay: 0.2s;
}

.App-scanCardTitle {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.range-slider {
  display: flex;
  flex-direction: column;
  align-items:flex-start
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  width: 340px; /* Adjust the width as desired */
  margin-left: 10px;
}

.range-slider input[type="range"]:focus {
  outline: none;
}

.range-slider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: #296154; /* Change the color to your desired green */
  border-radius: 3px;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #296154; /* Change the color to your desired green */
  border-radius: 50%;
  margin-top: -7px;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100;
  }
}